export const sanitizeData = (data) => {
  const sanitizedData = { ...data }
  Object.keys(sanitizedData).forEach((key) => {
    if (sanitizedData[key] === undefined) {
      sanitizedData[key] = null
    }
  })
  return sanitizedData
}

export function generateUUID() {
  // Public Domain/MIT
  let d = new Date().getTime() // Timestamp
  let d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0 // Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 // random number between 0 and 16
    if (d > 0) {
      // Use timestamp until depleted
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else {
      // Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16)
  })
}

export const convertProperties = (props) => {
  return props
    .filter((x) => !x.hideEdit)
    .reduce((prev, cur) => {
      const obj =
        cur.type === "object"
          ? {
              type: "object",
              title: cur.label,
              properties: cur.properties,
            }
          : cur.type === "array"
          ? {
              type: "array",
              title: cur.label,
              items: cur.items,
            }
          : cur.enum
          ? { enum: cur.enum, type: cur.type || "string", title: cur.label }
          : { type: cur.type || "string", title: cur.label }
      return {
        ...prev,
        [cur.name]: obj,
      }
    }, {})
}

export const getSchema = (title, props) => {
  return {
    title,
    type: "object",
    required: props
      .filter((item) => {
        if (typeof item.required === "undefined") {
          return true
        }

        if (item.required) {
          return true
        }

        return false
      })
      .map((item) => item.name),
    properties: convertProperties(props),
  }
}

export const getHash = async (base64String) => {
  // Decode the base64 string into an ArrayBuffer
  const buffer = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0))

  // Use the SubtleCrypto API to generate the SHA-256 hash
  const hashBuffer = await crypto.subtle.digest("SHA-256", buffer)

  // Convert the hash buffer to a hex string
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, "0")).join("")

  return hashHex
}

export const isBase64Image = (url) => {
  if (!url) {
    console.error("isBase64Image----url ERROR--->", url)
    return url
  }
  // Check if the URL is a base64 encoded string
  const base64Pattern = /^data:image\/(\w+);base64,/
  const match = url.match(base64Pattern)

  return match
}

export const getFileExtension = (url) => {
  // Check if the URL is a base64 encoded string
  const match = isBase64Image(url)

  if (match) {
    // If it's a base64 string, return the image type
    return match[1]
  } else {
    // Otherwise, extract the file extension from the URL
    const urlParts = url.split("?")[0] // Remove query parameters if any
    const fileName = urlParts.split("/").pop() // Get the file name
    const extension = fileName.split(".").pop() // Get the file extension

    return extension
  }
}

export const formatNumber = (number, locale = "en-US") => {
  if (!number) {
    return ""
  }
  const options = { maximumSignificantDigits: 21, maximumFractionDigits: 21 }
  if (typeof number === "string") {
    number = number || "0"

    let parsed = Number.parseFloat(number)
    parsed = isNaN(parsed) ? 0 : parsed
    const result = parsed.toLocaleString(locale, options)
    return result
  }

  return number.toLocaleString(locale, options)
}

export const formatCash = (n, maxAbbreviateDigits = 3, precision = 1) => {
  n = +(n || 0)
  maxAbbreviateDigits = Number(`1e${maxAbbreviateDigits}`)
  if (n < maxAbbreviateDigits) return n.toLocaleString()
  const abbreviations = ["K", "M", "B", "T"]
  if (n < 1e3) return n
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(precision).toLocaleString() + abbreviations[0]
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(precision).toLocaleString() + abbreviations[1]
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(precision).toLocaleString() + abbreviations[2]
  if (n >= 1e12) return +(n / 1e12).toFixed(precision).toLocaleString() + abbreviations[3]
}

export const convertToMeasure = (val) => {
  if (!val) {
    return "0"
  }

  if (/\d+\D+$/.test(val)) {
    return val
  } else {
    return `${val}px`
  }
}

export function getLocationStr(loc, t) {
  if (!loc) {
    return ""
  }

  const street = loc.street && loc.street !== "*" ? `${loc.street} ` : ""
  const city = loc.city && loc.city !== "*" ? `${`${loc.city}, `}` : ""
  const province = loc.province && loc.province !== "*" ? loc.province : ""
  const county =
    loc.county && loc.county !== "*" ? (province ? `, ${loc.county} ` : ` ${loc.county} `) : ""
  let country = loc.country && loc.country !== "*" ? loc.country : ""

  country = province ? ` (${country})` : country
  // console.log("street || city || county || province -------->", { street, city, county, province })
  return street || city || county || province || country
    ? `${street}${city}${province}${county}${country}`
    : t
    ? t("any")
    : "*"
}

// import {RJSFSchema} from '@rjsf/utils';
import React, { useEffect, useState } from 'react';
import { auth, db, functions } from '../firebase';
import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

import { AddBox as AddIcon } from '@material-ui/icons';
import { Button } from '@mui/material';
import Form from '@rjsf/material-ui';
import { LoadingService } from '../Loading';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import validator from '@rjsf/validator-ajv8';

const sendNotifications = httpsCallable(functions, 'sendNotifications');

const uiSchema = {
  "ui:widget": "checkboxes",
  locationRadio: {
    "ui:widget": "RadioWidget",
  },
  selectedUsers: {
    "ui:options": {
      inline: true,
      addable: false,
    },
    items: { uid: { "ui:widget": "hidden" }, pushNotificationToken: { "ui:widget": "hidden" } }
  },
  notificationText: {
    "ui:widget": "textarea",
  }
  // foo: {"ui:widget": "hidden"}
};
/*

  const schema = {
    title: 'Notifications Config',
    type: 'object',
    // required: ['email', 'password'],
    properties: {
      sendNotificationToUser: {
        "type": "array",
        "title": "Choose User",
        "items": {
          "type": "string",
          "enum": [
            "foo",
            "bar",
            "fuzz",
            "qux"
          ]
        },
      },
      password: { type: 'string', title: 'Password' },
    },
  };
*/

const schema = {
  // title: 'Notifications Config',
  type: "object",
  required: ['notificationTitle', 'notificationText'],
  properties: {
    selectedUsers: {
      type: "array",
      title: "Selected Users",
      items: {
        type: "object",
        title: "",
        properties: {
          name: { type: "string", readOnly: true },
          uid: { type: "string" },
          pushNotificationToken: { type: "string" },
        },
      },
    },

    notificationTitle: { type: "string", title: "Notification Title" },
    notificationText: { type: "string", title: "Notification Text" },
  },
};
let timer = null;

const NotificationConfig = () => {
  // const formRef = useRef();

  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const [filterName, setFilterName] = useState(null);

  const [filterSchema, setFilterSchema] = useState({});
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [filterFormData, setFilterFormData] = useState({});

  async function loadData() {
    try {

      LoadingService.setLoading(true);
      const path = `users`;

      // const querySnapshot = await getDocs(collection(db, ...path));

      const querySnapshot =
        filterName?.length ?
          await getDocs(query(collection(db, path), where('name', '>=', filterName), where('name', '<', filterName
            .replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1))), orderBy('name'), limit(50)))
          : await getDocs(query(collection(db, path), orderBy('name'), limit(50)));

      const data = [];
      querySnapshot.forEach(doc => {
        const d = doc.data();
        if (d.pushNotificationToken && (typeof d.pushNotifications === 'undefined' || d.pushNotifications)) {
          data.push(d);
        }
      });

      setUsers(data);
    } catch (error) {
      console.log('ERROR ---loading users data---->', error);
    } finally {
      LoadingService.setLoading(false);
    }

  }

  useEffect(() => {
    loadData();
    // getData({ setData: setUsers, path, getMap, isCollection: true, filterFunction: item => !item.phone && !item.email });

  }, [filterName]);

  useEffect(() => {
    console.log('users---->', users);
    if (users.length) {
      setFilterSchema({
        // title: 'Notifications Config',
        type: 'object',
        // required: ['email', 'password'],
        definitions: {
          users: {
            enumNames: users.map(({ name, email, phoneNumber }) => `${name || ''}(${email || phoneNumber})`),
            enum: users.map(({ name, uid, email, phoneNumber, pushNotificationToken }) => ({ name: `${name || ''}(${email || phoneNumber})`, uid, pushNotificationToken }))
          }
        },
        properties: {
          filterUsers: { type: 'string', title: 'Filter Users' },
          users: {
            title: "Users",
            "type": "array",
            // uniqueItems: true,
            items: {
              type: 'string',
              $ref: "#/definitions/users",
            }

          },
        },
      });
    }

  }, [users, selectedUsers]);

  return (
    <div>
      <p>User from the list are limited to 50 you must use the filter to find specific users to send notifications to. These users must have a notification token and have the notification option enable from the profile</p>
      {!filterSchema?.properties ? null : <Form
        noValidate
        formData={filterFormData}
        schema={filterSchema}
        uiSchema={uiSchema}
        validator={validator}
        onChange={(form) => {
          // const selected = form?.formData?.users?.filter(x => !!x) || [];
          setFilterFormData(form.formData);

          if (timer) {
            clearTimeout(timer);
          }

          timer = setTimeout(() => {
            setFilterName(form.formData.filterUsers);
          }, 1000);


          // setSelectedUsers(form?.formData?.users?.filter(x => !!x) || []);
          // console.log('form.formData--filterSchema-->', form.formData);
        }}
        onSubmit={async (form) => {
          const selected = form?.formData?.users?.filter(x => !!x) || [];
          const ss = [...(formData.selectedUsers || []), ...selected];
          setFormData({ ...formData, selectedUsers: ss });
          setFilterFormData(d => {
            return { ...d, users: [] };
          });
        }}
        onError={error => console.log('ERROR!!!', error)}
      >
        <div>
          <Button type="submit"><AddIcon fontSize="large" /></Button>
        </div>
      </Form>}

      {!schema?.properties ? null : <Form
        formData={formData}
        schema={schema}
        uiSchema={uiSchema}
        validator={validator}
        onChange={(form) => {
          setFormData(form.formData);
        }}
        onSubmit={async (form) => {

          /*
            data: data.data || null,
            notification: data.notification || null,
            condition: data.condition,
            token: data.token || null,
            tokens: data.tokens || null,
            topic: data.topic || null
          */

          const data = form.formData;
          if (data?.selectedUsers?.length) {
            sendNotifications({
              notification: {
                title: data.notificationTitle,
                body: data.notificationText
              },
              tokens: data.selectedUsers.map(x => x.pushNotificationToken)
            });
          } else {
            sendNotifications({
              notification: {
                title: data.notificationTitle,
                body: data.notificationText
              }
            });
          }
         
          console.log('form.formData--formData-->', form);
        }}
        onError={error => console.log('ERROR!!!', error)}
      />}
    </div>
  );
};

export default NotificationConfig;

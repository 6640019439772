import { Card, CardContent, Typography } from '@mui/material';

// TicketPreview.js
import React from 'react';

const TicketPreview = ({ design }) => {
    const {
        backgroundColor,
        color,
        height,
        justifyContent,
        margin,
        padding,
        textColor,
        width,
    } = design;

    return (
        <Card
            style={{
                backgroundColor,
                color,
                height,
                margin,
                padding,
                width,
                display: 'flex',
                justifyContent,
                alignItems: 'center',
                textAlign: 'center',
            }}
        >
            <CardContent className='flex w-full flex-wrap'>
                <Typography  variant="h5" style={{ color: textColor }}>
                    Ticket Preview
                    {JSON.stringify(design)}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default TicketPreview;
